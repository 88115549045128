import React from "react"
import { Link } from "gatsby"

const Cathero = props => {
  let id = props.wordpress_id
  let aboutusid, harassmentSpan
  if (id == 1006) {
    aboutusid = "about-us"
  } else if (id == 464) {
    harassmentSpan = "d-none"
  } else if (id == 660) {
    harassmentSpan = "d-none"
  } else {
    aboutusid = ""
    harassmentSpan = ""
  }
  return (
    <>
      <div className="container-fluid catheromain" id={aboutusid}>
        <div className="row ">
          <div className="col-lg-1"></div>
          <div className="col-lg-4 col-md-12  d-flex justify-content-center flex-column hero-cont ">
            <div className="title">
              <h1 className="heading">{props.data}</h1>
              <span className={"head-span " + harassmentSpan}>
                {props.subtitle}
              </span>
            </div>
            <div className="title-desc pt-lg-3 pb-lg-3">
              <h3 className="description">{props.desc}</h3>
            </div>

            <div className="btn-demo">
              <Link to="/contact#schedule" className="hero-btn">
                <button className="btn-schedule-demo">Schedule a Demo</button>
              </Link>
            </div>
          </div>
          <div className="col-lg-6 col-md-12  d-flex justify-content-center flex-column hero-img">
            <picture>
              <source media="(max-width:768px)" srcset={props.tabimage} />

              <source media="(max-width:480px)" srcset={props.mobimage} />

              <img
                src={props.img}
                alt={props.data}
                className="hero-landingsrc"
              />
            </picture>
          </div>

          <div className="col-lg-1"></div>
        </div>
      </div>
    </>
  )
}

export default Cathero
