import React from "react"
import { Link } from "gatsby"
import Cathero from "../components/Category-hero"
import Layout from "../components/layout"
import Logoimg from "../components/Clients"
import SEO from "../components/Seo"

import Quote from "../components/Testimonials"
import Advantage from "../components/Succeed-advantage"
import Crsehighlight from "../components/Course-highlights"
const Harassment = ({ data }) => {
  let Harassmentquery = data.wordpressPage
  let yoast = data.wordpressPage.yoast_head
  return (
    <>
      <Layout
        logo={data.allWordpressAcfSucceedlearnLogo.edges}
        favicon={data.allWordpressAcfSucceedlearnApple.edges}
      >
        <SEO title={{ yoast }} />

        <section className="financial-hero securityhead" id="financial-hero">
          <Cathero
            data={Harassmentquery.acf.title}
            img={
              Harassmentquery.acf.image.localFile.childImageSharp.fluid
                .originalImg
            }
            tabimage={
              Harassmentquery.acf.tablet_image.localFile.childImageSharp.fluid
                .originalImg
            }
            mobimage={
              Harassmentquery.acf.mobile_image.localFile.childImageSharp.fluid
                .originalImg
            }
            desc={Harassmentquery.acf.description}
            subtitle={Harassmentquery.acf.subtitle}
            wordpress_id={Harassmentquery.wordpress_id}
          />
        </section>

        <section className="clients" id="clients">
          <Logoimg
            clientsrow={data.allWordpressAcfClientsLogos}
            title={data.wordpressPage.acf.clients_title}
          />
          <hr className="client-below" />
        </section>

        {/* Advantage Section */}
        <Advantage
          title={Harassmentquery.acf.adv_title}
          img1={
            Harassmentquery.acf.imageone.localFile.childImageSharp.fluid
              .originalImg
          }
          img2={
            Harassmentquery.acf.imagesecond.localFile.childImageSharp.fluid
              .originalImg
          }
          img3={
            Harassmentquery.acf.imagethree.localFile.childImageSharp.fluid
              .originalImg
          }
          imgdesc1={Harassmentquery.acf.image1desc}
          imgdesc2={Harassmentquery.acf.image2}
          imgdesc3={Harassmentquery.acf.image3}
        />
        <hr className="client-below" />

        <section className="geography" id="geography">
          <div className="container">
            <div className="row info">
              <div className="col-lg-3"></div>
              <div className="col-lg-6 col-md-12 mob-geo ">
                <div className="title-geo">
                  <h3>For your Geography</h3>
                </div>
              </div>
              <div className="col-lg-3"></div>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-12 geo-1-img">
                <img
                  src="https://res.cloudinary.com/dhrgc7mqu/image/upload/v1609134757/succeedlearn/cc575bk5umcw9wnyryn2.png"
                  alt="shpt-us"
                />
              </div>
              <div className="col-lg-8 col-md-12  geo-1">
                <div class="card">
                  <div class="card-header">
                    Sexual Harassment Prevention - &nbsp;
                    <span className="subtitle">USA</span>
                  </div>
                  <div class="card-body">
                    <ul class="round">
                      <li>
                        Sexual Harassment Prevention for Employees eLearning
                      </li>

                      <li>
                        Sexual Harassment Prevention for Supervisors eLearning
                      </li>
                      <li className="last">
                        Covers all Federal and State Laws including CA, NY, NJ,
                        IL etc.
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="btn-geo-1">
                  <Link
                    to="/Harassment-discrimination-courses/sexual-harassment-prevention-training"
                    className="btn btn-geo-btn"
                  >
                    Learn More
                  </Link>
                </div>
                <div className="bottom-line"></div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-8 col-md-12  geo-2 order-2 order-lg-1 ">
                <div class="card">
                  <div class="card-header">
                    Equality, Diversity & Inclusion <br />
                    <span className="subtitle">
                      - UK,EU, Australia, Nordic, Singapore and more
                    </span>
                  </div>
                  <div class="card-body">
                    <ul class="round">
                      <li>Legally curated eLearning courses</li>
                      <li className="last">
                        Covers legal frameworks for UK, EU, Australia, Nordic,
                        Singapore and more
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="btn-geo-2">
                  <Link
                    to="/Harassment-discrimination-courses/equality_and_diversity"
                    className="btn btn-geo-btn"
                  >
                    Learn More
                  </Link>
                </div>
                <div className="bottom-line"></div>
              </div>

              <div className="col-lg-4 order-md-1 order-1 col-md-12 geo-2-img order-1 order-lg-2 ">
                <img
                  src="https://res.cloudinary.com/dhrgc7mqu/image/upload/v1609134758/succeedlearn/hj9bnbtnaqnb77qlypoj.png"
                  alt="shpt-us2"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-lg-4 col-md-12 geo-3-img">
                <img
                  src="https://res.cloudinary.com/dhrgc7mqu/image/upload/v1609134758/succeedlearn/fckqmljlcom9uedu1vyl.png"
                  alt="shpt-us"
                />
              </div>
              <div className="col-lg-8 col-md-12  geo-3">
                <div class="card">
                  <div class="card-header">
                    Prevention of Sexual Harassment (POSH)
                    <span className="subtitle"> - India</span>
                  </div>
                  <div class="card-body">
                    <ul class="round">
                      <li>
                        POSH Foundation for staff (eLearning available in
                        English and 7 Indian languages)
                      </li>
                      <li>POSH for Managers (eLearning Modules and Webinar)</li>
                      <li className="last">
                        POSH for Internal Committee Members (eLearning Modules
                        and 3 Webinars)
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="btn-geo-3">
                  <Link
                    to="https://elearnposh.com/"
                    className="btn btn-geo-btn"
                  >
                    Learn More
                  </Link>
                </div>
                <div className="bottom-line"></div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-8 col-md-12  geo-4 order-2 order-lg-1 ">
                <div class="card">
                  <div class="card-header">
                    Unconscious Bias <br />
                  </div>

                  <div class="card-body">
                    <ul class="round">
                      <li>
                        Facilitate an inclusive and diverse workplace by
                        eliminating Unconscious Bias
                      </li>
                      <li className="last">
                        Make your employees develop comprehensive understanding
                        on Unconsious Bias and its types
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="btn-geo-4">
                  <Link
                    to="/Harassment-discrimination-courses/unconscious-bias-elearning"
                    className="btn btn-geo-btn"
                  >
                    Learn More
                  </Link>
                </div>
                <div className="bottom-line"></div>
              </div>
              <div className="col-lg-4 order-md-1 order-1 col-md-12 geo-4-img order-1 order-lg-2 ">
                <img
                  src="https://res.cloudinary.com/dhrgc7mqu/image/upload/v1627024512/succeedlearn/tyrqcoc6ukhife9io6td.png"
                  alt="unconsicious"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="bestprice" id="bestprice">
          <div className="container">
            <div className="row">
              <div className="col-lg-6  col-md-12 img-sect">
                <img
                  src={
                    Harassmentquery.acf.demo_title_image.localFile
                      .childImageSharp.fluid.originalImg
                  }
                  alt="best-price3"
                />

                <img
                  src={
                    Harassmentquery.acf.demo_subtitle_image.localFile
                      .childImageSharp.fluid.originalImg
                  }
                  className="incred-price"
                  alt="best-price1"
                />

                <div className="price-btn">
                  <Link to="/contact#schedule">
                    <button className="btn btn-lg btn-price-btn">
                      Let's discuss!
                    </button>
                  </Link>
                </div>
              </div>
              <div className="col-lg-6  col-md-12 best-price-human">
                <img
                  src={
                    Harassmentquery.acf.human_image.localFile.childImageSharp
                      .fluid.originalImg
                  }
                  alt="best-price"
                />
              </div>
            </div>
          </div>
        </section>

        {/* Course highlights */}

        <section className="harass-highlights" id="harass-highlights">
          <Crsehighlight
            page={"Harassment"}
            section={"highlights"}
            h3title={"title-highlights"}
            harassh3title={"title-harass-highlights"}
            harassection={"harass-highlights"}
            img1={
              Harassmentquery.acf.crse_highlights1.localFile.childImageSharp
                .fluid.originalImg
            }
            crsetitle1={
              Harassmentquery.acf.crse_highlights1_title_and_description
            }
            img2={
              Harassmentquery.acf.crse_highlights2.localFile.childImageSharp
                .fluid.originalImg
            }
            crsetitle2={
              Harassmentquery.acf.crse_highlights2_title_and_description
            }
            img3={
              Harassmentquery.acf.crse_highlights3.localFile.childImageSharp
                .fluid.originalImg
            }
            crsetitle3={
              Harassmentquery.acf.crse_highlights3_title_and_description
            }
            img4={
              Harassmentquery.acf.crse_highlights4.localFile.childImageSharp
                .fluid.originalImg
            }
            crsetitle4={
              Harassmentquery.acf.crse_highlights4_title_and_description
            }
            img5={
              Harassmentquery.acf.crse_highlights5.localFile.childImageSharp
                .fluid.originalImg
            }
            crsetitle5={
              Harassmentquery.acf.crse_highlights5_title_and_description
            }
            img6={
              Harassmentquery.acf.crse_highlights6.localFile.childImageSharp
                .fluid.originalImg
            }
            crsetitle6={
              Harassmentquery.acf.crse_highlights6_title_and_description
            }
            img7={
              Harassmentquery.acf.crse_highlights7.localFile.childImageSharp
                .fluid.originalImg
            }
            crsetitle7={
              Harassmentquery.acf.crse_highlights7_title_and_description
            }
            img8={
              Harassmentquery.acf.crse_highlights8.localFile.childImageSharp
                .fluid.originalImg
            }
            crsetitle8={
              Harassmentquery.acf.crse_highlights8_title_and_description
            }
            img9={
              Harassmentquery.acf.crse_highlights9.localFile.childImageSharp
                .fluid.originalImg
            }
            crsetitle9={
              Harassmentquery.acf.crse_highlights9_title_and_description
            }
          />
        </section>

        <hr style={{ margin: 0, borderTop: "2px solid #a8a8a8" }} />

        <section className="testimonials" id="testimonials">
          <Quote
            testimonialtitle={"What our clients have to say"}
            title={"Roja Puppala  "}
            subtitle={"Learning & Development Specialist"}
            description={
              "We love Succeed’s engaging and interactive course on Information Security Awareness. The team is easy to work with and understand our unique learning requirements. The user completion reports are comprehensive and suits our corporate needs. Would highly recommend Succeed to anyone looking for an online course on Information Security Awareness."
            }
          />
        </section>

        <section className="discrim-demo" id="discrim-demo">
          <div className="container-fluid discrim-bg-grey ">
            <div className="row bg-color">
              <div className="col-lg-2"></div>
              <div className="col-lg-8 ">
                <div className="row">
                  <div className="col-lg-6 col-md-12 justify-content-center d-flex  flex-column schedule-discrim-demo">
                    <h5>
                      See How Succeed
                      <br /> will work for
                      <br /> your organization
                    </h5>
                    <div className="discrim-demobtn">
                      <Link to="/contact#schedule">
                        <button className="discrim-demo-btn">
                          Schedule a Demo
                        </button>
                      </Link>
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-12 text-md-center align-tablet">
                    <img
                      src={
                        Harassmentquery.acf.bottom.localFile.childImageSharp
                          .fluid.originalImg
                      }
                      alt="succeed-works"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-2"></div>
            </div>
          </div>
        </section>

        <section className="course-domains" id="course-domains">
          <div className="container">
            <div className="row">
              <div className="col-md-3"></div>
              <div className="col-md-6 title">
                <h5>Our Other Course Domains</h5>
              </div>
              <div className="col-md-3"></div>
            </div>

            <div className="row py-3">
              <div className="col-lg-4 col-md-12 crs-card">
                <div className="card" style={{ width: "18rem" }}>
                  <Link to="/Infosec-data-protection">
                    <img
                      className="card-img-top"
                      src="https://res.cloudinary.com/dhrgc7mqu/image/upload/v1608039154/succeedlearn/bttjvhuyluw9jtpaed5k.png"
                      alt="infosec"
                      style={{ margin: 0 }}
                    />
                    <div className="card-body card-titledesc1 cardsec">
                      <p className="card-text">Security Awareness</p>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 crs-card">
                <Link to="/financial-compliance/">
                  <div className="card" style={{ width: "18rem" }}>
                    <img
                      className="card-img-top"
                      src="https://res.cloudinary.com/dhrgc7mqu/image/upload/v1606990669/succeedlearn/h7vqfalfwttrez5nnwmq.png"
                      alt="infosec"
                      style={{ margin: 0 }}
                    />
                    <div className="card-body card-titledesc2 finan">
                      <p className="card-text">Financial Crime Prevention</p>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 col-md-12 crs-card">
                <Link to="/health-and-safety-courses/">
                  <div className="card" style={{ width: "18rem" }}>
                    <img
                      className="card-img-top"
                      src="https://res.cloudinary.com/dhrgc7mqu/image/upload/v1606990669/succeedlearn/qdrqit2blcwwvnv6p2qp.png"
                      alt="infosec"
                      style={{ margin: 0 }}
                    />
                    <div className="card-body card-titledesc3 health">
                      <p className="card-text">Health & Safety</p>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}

export default Harassment

export const Harassmentquery = graphql`
  {
    allWordpressAcfSucceedlearnLogo {
      edges {
        node {
          acf {
            logo_image_ {
              localFile {
                childImageSharp {
                  fluid {
                    originalImg
                  }
                }
              }
            }
          }
        }
      }
    }
    allWordpressAcfSucceedlearnApple {
      edges {
        node {
          acf {
            logo_image_ {
              localFile {
                childImageSharp {
                  fluid {
                    originalImg
                  }
                }
              }
            }
          }
        }
      }
    }

    wordpressPage(title: { eq: "Harassment discrimination courses" }) {
      wordpress_id
      acf {
        clients_title
        title
        description
        image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        tablet_image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        mobile_image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }

        adv_title
        image1desc
        imageone {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
          alt_text
        }
        image2
        imagesecond {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
          alt_text
        }

        image3
        imagethree {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
          alt_text
        }

        human_image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        demo_title_image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        demo_subtitle_image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }

        bottom {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }

        crse_highlights1_title_and_description
        crse_highlights1 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }

        crse_highlights2_title_and_description
        crse_highlights2 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }

        crse_highlights3_title_and_description
        crse_highlights3 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }

        crse_highlights4_title_and_description
        crse_highlights4 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }

        crse_highlights5_title_and_description
        crse_highlights5 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }

        crse_highlights6_title_and_description
        crse_highlights6 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }

        crse_highlights7_title_and_description
        crse_highlights7 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }

        crse_highlights8_title_and_description
        crse_highlights8 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }

        crse_highlights9_title_and_description
        crse_highlights9 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
      }
      yoast_head
    }

    allWordpressAcfClientsLogos {
      edges {
        node {
          acf {
            client_logo_images {
              localFile {
                childImageSharp {
                  fluid {
                    originalImg
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
