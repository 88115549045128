import React from "react"

import parser from "react-html-parser"

import Img from "gatsby-image"
const Crsehighlights = props => {
  const Page = props.page
  const Pages = props.pages
  let h3titles

  if (Page == "Harassment") {
    h3titles = props.harassh3title
  } else if (Page == "Financial") {
    h3titles = props.financialh3title
  } else {
    h3titles = props.h3title
  }
  return (
    <>
      <div className="container">
        <div className="row info">
          <div className="col-lg-3"></div>
          <div className="col-lg-6 harass-title-course">
            <div className={h3titles}>
              <h3>Highlights</h3>
            </div>
          </div>
          <div className="col-lg-3"></div>
        </div>

        <div className="row text-center py-lg-4  py-md-4 py-3 mobile-padding-top">
          <div className="col-md-4 py-3 py-lg-2 crs-mobile ">
            <div className="img">
              <img src={props.img1} alt={parser(props.crsetitle1)} />
            </div>
            <div className="title-desc">{parser(props.crsetitle1)}</div>
          </div>
          <div className="col-md-4 py-3 py-lg-2 crs-mobile">
            <div className="img">
              <img src={props.img2} alt={parser(props.crsetitle2)} />
            </div>
            <div className="title-desc">{parser(props.crsetitle2)}</div>
          </div>
          <div className="col-md-4 py-3 py-lg-2 crs-mobile">
            <div className="img">
              <img src={props.img3} alt={parser(props.crsetitle3)} />
            </div>
            <div className="title-desc">{parser(props.crsetitle3)}</div>
          </div>
        </div>

        <div className="row text-center py-lg-4  py-md-4  ">
          <div className="col-md-4 py-3 py-lg-2 crs-mobile ">
            <div className="img">
              <img src={props.img4} alt={parser(props.crsetitle4)} />
            </div>
            <div className="title-desc">{parser(props.crsetitle4)}</div>
          </div>
          <div className="col-md-4 py-3 py-lg-2 crs-mobile">
            <div className="img">
              <img src={props.img5} alt={parser(props.crsetitle5)} />
            </div>
            <div className="title-desc">{parser(props.crsetitle5)}</div>
          </div>
          <div className="col-md-4 py-3 py-lg-2 crs-mobile">
            <div className="img">
              <img src={props.img6} alt={parser(props.crsetitle6)} />
            </div>
            <div className="title-desc">{parser(props.crsetitle6)}</div>
          </div>
        </div>

        <div className="row text-center py-lg-4  py-md-4  ">
          <div className="col-md-4 py-3 py-lg-2 crs-mobile">
            <div className="img">
              <img src={props.img7} alt={parser(props.crsetitle7)} />
            </div>
            <div className="title-desc">{parser(props.crsetitle7)}</div>
          </div>
          <div className="col-md-4 py-3 py-lg-2 crs-mobile">
            <div className="img">
              <img src={props.img8} alt={parser(props.crsetitle8)} />
            </div>
            <div className="title-desc">{parser(props.crsetitle8)}</div>
          </div>
          <div className="col-md-4 py-3 py-lg-2 crs-mobile">
            <div className="img">
              <img src={props.img9} alt={parser(props.crsetitle9)} />
            </div>
            <div className="title-desc">{parser(props.crsetitle9)}</div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Crsehighlights
