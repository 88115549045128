import React from "react"

import Img from "gatsby-image"

const Advantage = props => {
  return (
    <>
      <section className="harasssucceed-adv" id="harasssucceed-adv">
        <div className="container">
          <div className="row">
            <div className="col-lg-2"></div>
            <div className="col-lg-8 col-md-12">
              <div className="heading text-center">
                <h3>{props.title}</h3>
              </div>
            </div>
            <div className="col-lg-2"></div>
          </div>

          <div className="row img-desc">
            <div className="col-md-12 col-lg-4">
              <img src={props.img1} alt={props.imgdesc1} />

              <h5>{props.imgdesc1}</h5>
            </div>

            <div className="col-md-12 col-lg-4">
              <img src={props.img2} alt={props.imgdesc2} />

              <h5>{props.imgdesc2}</h5>
            </div>
            <div className="col-md-12 col-lg-4">
              <img src={props.img3} alt={props.imgdesc3} />

              <h5>{props.imgdesc3}</h5>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Advantage
